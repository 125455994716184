
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as accountLUTTRGMhg3Meta } from "/vercel/path0/pages/account.vue?macro=true";
import { default as _91provider_93ByrgCww4oyMeta } from "/vercel/path0/pages/auth/[provider].vue?macro=true";
import { default as createD7pFAExMYrMeta } from "/vercel/path0/pages/content-suite/create.vue?macro=true";
import { default as indexNProitM0KmMeta } from "/vercel/path0/pages/content-suite/index.vue?macro=true";
import { default as create_45contentXqeL3qtCNmMeta } from "/vercel/path0/pages/content-suite/manage-course/[managedCourse]/create-content.vue?macro=true";
import { default as edit_45contentjqLeNMhw83Meta } from "/vercel/path0/pages/content-suite/manage-course/[managedCourse]/edit-content.vue?macro=true";
import { default as editGPewl13lacMeta } from "/vercel/path0/pages/content-suite/manage-course/[managedCourse]/edit.vue?macro=true";
import { default as indexgWZstHRv2JMeta } from "/vercel/path0/pages/content-suite/manage-course/[managedCourse]/index.vue?macro=true";
import { default as continue_45journeyzdSO9soKDBMeta } from "/vercel/path0/pages/continue-journey.vue?macro=true";
import { default as _91course_936mMT4TnO6FMeta } from "/vercel/path0/pages/courses/[course].vue?macro=true";
import { default as indexnTYY7S14SKMeta } from "/vercel/path0/pages/courses/analytics/courses/[course]/index.vue?macro=true";
import { default as indexF5Lex1apT1Meta } from "/vercel/path0/pages/courses/analytics/courses/index.vue?macro=true";
import { default as index8cVR0PI0iIMeta } from "/vercel/path0/pages/courses/analytics/index.vue?macro=true";
import { default as categoriesg7H06pV9Z8Meta } from "/vercel/path0/pages/courses/categories.vue?macro=true";
import { default as _91challengeCourse_93bsJM03ajTSMeta } from "/vercel/path0/pages/courses/challenges/[challengeCourse].vue?macro=true";
import { default as conceptsDSZf1oyMZXMeta } from "/vercel/path0/pages/courses/concepts.vue?macro=true";
import { default as _91course_93mLwSEyWrAKMeta } from "/vercel/path0/pages/courses/content/[course].vue?macro=true";
import { default as indexkaXcMWGIjPMeta } from "/vercel/path0/pages/courses/index.vue?macro=true";
import { default as feedVIYG8VO9tQMeta } from "/vercel/path0/pages/feed.vue?macro=true";
import { default as creators4CUPvbiVgeMeta } from "/vercel/path0/pages/guides/creators.vue?macro=true";
import { default as learnerswEHTauT2fFMeta } from "/vercel/path0/pages/guides/learners.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as completed_45courses2jAvmUVRGeMeta } from "/vercel/path0/pages/library/completed-courses.vue?macro=true";
import { default as ongoing_45courses1ZBRgRDPl7Meta } from "/vercel/path0/pages/library/ongoing-courses.vue?macro=true";
import { default as watchlist7vOfHEsVFUMeta } from "/vercel/path0/pages/library/watchlist.vue?macro=true";
import { default as _91mentor_93VK5iCvAoTkMeta } from "/vercel/path0/pages/mentors/[mentor].vue?macro=true";
import { default as createlbxDliRyxKMeta } from "/vercel/path0/pages/mentors/create.vue?macro=true";
import { default as indexSULXDU8lzLMeta } from "/vercel/path0/pages/mentors/index.vue?macro=true";
import { default as new_45journeycvdS5wvQUYMeta } from "/vercel/path0/pages/new-journey.vue?macro=true";
import { default as _91note_9376clHm4WQYMeta } from "/vercel/path0/pages/notes/[note].vue?macro=true";
import { default as createHFwIxqITs0Meta } from "/vercel/path0/pages/notes/create.vue?macro=true";
import { default as indexwZwrYlKSkGMeta } from "/vercel/path0/pages/notes/index.vue?macro=true";
import { default as request_45resetXdG0kMqTcQMeta } from "/vercel/path0/pages/password/request-reset.vue?macro=true";
import { default as reset_45password1ZvKw2gBJ9Meta } from "/vercel/path0/pages/password/reset-password.vue?macro=true";
import { default as _91assessment_93tS8tt9uxneMeta } from "/vercel/path0/pages/performance-reviews/[assessment].vue?macro=true";
import { default as _91courseAssessment_93KE9nvE6hVEMeta } from "/vercel/path0/pages/performance-reviews/courses/[courseAssessment].vue?macro=true";
import { default as index27duBSe5G9Meta } from "/vercel/path0/pages/performance-reviews/courses/index.vue?macro=true";
import { default as indexyIq7zEjQMDMeta } from "/vercel/path0/pages/performance-reviews/index.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as coursesFy1YpfwizHMeta } from "/vercel/path0/pages/profile/[username]/courses.vue?macro=true";
import { default as indexwAezHNjIe3Meta } from "/vercel/path0/pages/profile/[username]/index.vue?macro=true";
import { default as setup_45profileTSS8eQWu65Meta } from "/vercel/path0/pages/setup-profile.vue?macro=true";
import { default as engagement_45pointsLhQbYxIQRyMeta } from "/vercel/path0/pages/store/engagement-points.vue?macro=true";
import { default as create_45materiallHPPpAiBfxMeta } from "/vercel/path0/pages/study-quests/[quest]/create-material.vue?macro=true";
import { default as editNSKhNKQf2iMeta } from "/vercel/path0/pages/study-quests/[quest]/edit.vue?macro=true";
import { default as indexcGefMO9aFuMeta } from "/vercel/path0/pages/study-quests/[quest]/index.vue?macro=true";
import { default as studyLOGsfIziGFMeta } from "/vercel/path0/pages/study-quests/[quest]/study.vue?macro=true";
import { default as indexh6p2lTWYioMeta } from "/vercel/path0/pages/study-quests/index.vue?macro=true";
import { default as new_45quest36gGY1h8tFMeta } from "/vercel/path0/pages/study-quests/new-quest.vue?macro=true";
import { default as terms_45of_45serviceSmXvkgSRNCMeta } from "/vercel/path0/pages/terms-of-service.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: accountLUTTRGMhg3Meta || {},
    component: () => import("/vercel/path0/pages/account.vue")
  },
  {
    name: "auth-provider",
    path: "/auth/:provider()",
    meta: _91provider_93ByrgCww4oyMeta || {},
    component: () => import("/vercel/path0/pages/auth/[provider].vue")
  },
  {
    name: "content-suite-create",
    path: "/content-suite/create",
    meta: createD7pFAExMYrMeta || {},
    component: () => import("/vercel/path0/pages/content-suite/create.vue")
  },
  {
    name: "content-suite",
    path: "/content-suite",
    meta: indexNProitM0KmMeta || {},
    component: () => import("/vercel/path0/pages/content-suite/index.vue")
  },
  {
    name: "content-suite-manage-course-managedCourse-create-content",
    path: "/content-suite/manage-course/:managedCourse()/create-content",
    meta: create_45contentXqeL3qtCNmMeta || {},
    component: () => import("/vercel/path0/pages/content-suite/manage-course/[managedCourse]/create-content.vue")
  },
  {
    name: "content-suite-manage-course-managedCourse-edit-content",
    path: "/content-suite/manage-course/:managedCourse()/edit-content",
    meta: edit_45contentjqLeNMhw83Meta || {},
    component: () => import("/vercel/path0/pages/content-suite/manage-course/[managedCourse]/edit-content.vue")
  },
  {
    name: "content-suite-manage-course-managedCourse-edit",
    path: "/content-suite/manage-course/:managedCourse()/edit",
    meta: editGPewl13lacMeta || {},
    component: () => import("/vercel/path0/pages/content-suite/manage-course/[managedCourse]/edit.vue")
  },
  {
    name: "content-suite-manage-course-managedCourse",
    path: "/content-suite/manage-course/:managedCourse()",
    meta: indexgWZstHRv2JMeta || {},
    component: () => import("/vercel/path0/pages/content-suite/manage-course/[managedCourse]/index.vue")
  },
  {
    name: "continue-journey",
    path: "/continue-journey",
    meta: continue_45journeyzdSO9soKDBMeta || {},
    component: () => import("/vercel/path0/pages/continue-journey.vue")
  },
  {
    name: "courses-course",
    path: "/courses/:course()",
    meta: _91course_936mMT4TnO6FMeta || {},
    component: () => import("/vercel/path0/pages/courses/[course].vue")
  },
  {
    name: "courses-analytics-courses-course",
    path: "/courses/analytics/courses/:course()",
    meta: indexnTYY7S14SKMeta || {},
    component: () => import("/vercel/path0/pages/courses/analytics/courses/[course]/index.vue")
  },
  {
    name: "courses-analytics-courses",
    path: "/courses/analytics/courses",
    meta: indexF5Lex1apT1Meta || {},
    component: () => import("/vercel/path0/pages/courses/analytics/courses/index.vue")
  },
  {
    name: "courses-analytics",
    path: "/courses/analytics",
    meta: index8cVR0PI0iIMeta || {},
    component: () => import("/vercel/path0/pages/courses/analytics/index.vue")
  },
  {
    name: "courses-categories",
    path: "/courses/categories",
    meta: categoriesg7H06pV9Z8Meta || {},
    component: () => import("/vercel/path0/pages/courses/categories.vue")
  },
  {
    name: "courses-challenges-challengeCourse",
    path: "/courses/challenges/:challengeCourse()",
    meta: _91challengeCourse_93bsJM03ajTSMeta || {},
    component: () => import("/vercel/path0/pages/courses/challenges/[challengeCourse].vue")
  },
  {
    name: "courses-concepts",
    path: "/courses/concepts",
    meta: conceptsDSZf1oyMZXMeta || {},
    component: () => import("/vercel/path0/pages/courses/concepts.vue")
  },
  {
    name: "courses-content-course",
    path: "/courses/content/:course()",
    meta: _91course_93mLwSEyWrAKMeta || {},
    component: () => import("/vercel/path0/pages/courses/content/[course].vue")
  },
  {
    name: "courses",
    path: "/courses",
    meta: indexkaXcMWGIjPMeta || {},
    component: () => import("/vercel/path0/pages/courses/index.vue")
  },
  {
    name: "feed",
    path: "/feed",
    meta: feedVIYG8VO9tQMeta || {},
    component: () => import("/vercel/path0/pages/feed.vue")
  },
  {
    name: "guides-creators",
    path: "/guides/creators",
    meta: creators4CUPvbiVgeMeta || {},
    component: () => import("/vercel/path0/pages/guides/creators.vue")
  },
  {
    name: "guides-learners",
    path: "/guides/learners",
    meta: learnerswEHTauT2fFMeta || {},
    component: () => import("/vercel/path0/pages/guides/learners.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "library-completed-courses",
    path: "/library/completed-courses",
    meta: completed_45courses2jAvmUVRGeMeta || {},
    component: () => import("/vercel/path0/pages/library/completed-courses.vue")
  },
  {
    name: "library-ongoing-courses",
    path: "/library/ongoing-courses",
    meta: ongoing_45courses1ZBRgRDPl7Meta || {},
    component: () => import("/vercel/path0/pages/library/ongoing-courses.vue")
  },
  {
    name: "library-watchlist",
    path: "/library/watchlist",
    meta: watchlist7vOfHEsVFUMeta || {},
    component: () => import("/vercel/path0/pages/library/watchlist.vue")
  },
  {
    name: "mentors-mentor",
    path: "/mentors/:mentor()",
    meta: _91mentor_93VK5iCvAoTkMeta || {},
    component: () => import("/vercel/path0/pages/mentors/[mentor].vue")
  },
  {
    name: "mentors-create",
    path: "/mentors/create",
    meta: createlbxDliRyxKMeta || {},
    component: () => import("/vercel/path0/pages/mentors/create.vue")
  },
  {
    name: "mentors",
    path: "/mentors",
    meta: indexSULXDU8lzLMeta || {},
    component: () => import("/vercel/path0/pages/mentors/index.vue")
  },
  {
    name: "new-journey",
    path: "/new-journey",
    meta: new_45journeycvdS5wvQUYMeta || {},
    component: () => import("/vercel/path0/pages/new-journey.vue")
  },
  {
    name: "notes-note",
    path: "/notes/:note()",
    meta: _91note_9376clHm4WQYMeta || {},
    component: () => import("/vercel/path0/pages/notes/[note].vue")
  },
  {
    name: "notes-create",
    path: "/notes/create",
    meta: createHFwIxqITs0Meta || {},
    component: () => import("/vercel/path0/pages/notes/create.vue")
  },
  {
    name: "notes",
    path: "/notes",
    meta: indexwZwrYlKSkGMeta || {},
    component: () => import("/vercel/path0/pages/notes/index.vue")
  },
  {
    name: "password-request-reset",
    path: "/password/request-reset",
    meta: request_45resetXdG0kMqTcQMeta || {},
    component: () => import("/vercel/path0/pages/password/request-reset.vue")
  },
  {
    name: "password-reset-password",
    path: "/password/reset-password",
    meta: reset_45password1ZvKw2gBJ9Meta || {},
    component: () => import("/vercel/path0/pages/password/reset-password.vue")
  },
  {
    name: "performance-reviews-assessment",
    path: "/performance-reviews/:assessment()",
    meta: _91assessment_93tS8tt9uxneMeta || {},
    component: () => import("/vercel/path0/pages/performance-reviews/[assessment].vue")
  },
  {
    name: "performance-reviews-courses-courseAssessment",
    path: "/performance-reviews/courses/:courseAssessment()",
    meta: _91courseAssessment_93KE9nvE6hVEMeta || {},
    component: () => import("/vercel/path0/pages/performance-reviews/courses/[courseAssessment].vue")
  },
  {
    name: "performance-reviews-courses",
    path: "/performance-reviews/courses",
    meta: index27duBSe5G9Meta || {},
    component: () => import("/vercel/path0/pages/performance-reviews/courses/index.vue")
  },
  {
    name: "performance-reviews",
    path: "/performance-reviews",
    meta: indexyIq7zEjQMDMeta || {},
    component: () => import("/vercel/path0/pages/performance-reviews/index.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacykJ3SQnmrN4Meta || {},
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "profile-username-courses",
    path: "/profile/:username()/courses",
    meta: coursesFy1YpfwizHMeta || {},
    component: () => import("/vercel/path0/pages/profile/[username]/courses.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: indexwAezHNjIe3Meta || {},
    component: () => import("/vercel/path0/pages/profile/[username]/index.vue")
  },
  {
    name: "setup-profile",
    path: "/setup-profile",
    meta: setup_45profileTSS8eQWu65Meta || {},
    component: () => import("/vercel/path0/pages/setup-profile.vue")
  },
  {
    name: "store-engagement-points",
    path: "/store/engagement-points",
    meta: engagement_45pointsLhQbYxIQRyMeta || {},
    component: () => import("/vercel/path0/pages/store/engagement-points.vue")
  },
  {
    name: "study-quests-quest-create-material",
    path: "/study-quests/:quest()/create-material",
    meta: create_45materiallHPPpAiBfxMeta || {},
    component: () => import("/vercel/path0/pages/study-quests/[quest]/create-material.vue")
  },
  {
    name: "study-quests-quest-edit",
    path: "/study-quests/:quest()/edit",
    meta: editNSKhNKQf2iMeta || {},
    component: () => import("/vercel/path0/pages/study-quests/[quest]/edit.vue")
  },
  {
    name: "study-quests-quest",
    path: "/study-quests/:quest()",
    meta: indexcGefMO9aFuMeta || {},
    component: () => import("/vercel/path0/pages/study-quests/[quest]/index.vue")
  },
  {
    name: "study-quests-quest-study",
    path: "/study-quests/:quest()/study",
    meta: studyLOGsfIziGFMeta || {},
    component: () => import("/vercel/path0/pages/study-quests/[quest]/study.vue")
  },
  {
    name: "study-quests",
    path: "/study-quests",
    meta: indexh6p2lTWYioMeta || {},
    component: () => import("/vercel/path0/pages/study-quests/index.vue")
  },
  {
    name: "study-quests-new-quest",
    path: "/study-quests/new-quest",
    meta: new_45quest36gGY1h8tFMeta || {},
    component: () => import("/vercel/path0/pages/study-quests/new-quest.vue")
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    meta: terms_45of_45serviceSmXvkgSRNCMeta || {},
    component: () => import("/vercel/path0/pages/terms-of-service.vue")
  }
]