import type { ChatCommandType, ChatCommandAction, ResponseStatus } from "~/utils/types";
import { RESPONSE_STATUS } from "~/constants/response-status.constant";

export const MAX_OUTPUT_TOKENS = 4096;

export const MIN_TOKENS_NEEDED_TO_REQUEST = 10;

export const MIN_SYSTEM_TOKENS_NEEDED_TO_REQUEST = 40_000;

export const CHAT_COMMANDS = {
  Summarize: "/summarize",
  GameMode: "/game",
  TutorMode: "/tutor",
  ClearChat: "/clear",
  Creativity: "/creativity",
  BriefResponse: "/response-brief",
  LongResponse: "/response-long",
  Language: "/language",
} as const;

export const CHAT_COMMAND_DESCRIPTIONS = {
  Summarize: "Summarize the course content",
  GameMode: "Start a new game with the specified source material. e.g. /game Naruto",
  TutorMode: "Start or continue a new tutoring session (default mode)",
  ClearChat: "Clears the chat (content will NOT be lost)",
  Creativity: "Set the mentor's creativity level. The range is (0 - 2). e.g. /creativity 0.5",
  BriefResponse: "Tells the mentor to provide brief responses",
  LongResponse: "Tells the mentor to be more expressive in their responses",
  Language: "Mentor Response Language. e.g. /language French",
} as const;

export const GAME_COMMAND_SOURCE_MATERIALS = {
  Naruto: "Naruto",
  OnePiece: "One Piece",
  DemonSlayer: "Demon slayer",
  TheWitcher: "The Witcher",
  GameOfThrones: "Game of Thrones",
  HarryPotter: "Harry Potter",
  TheAvengers: "The Avengers",
  MortalKombat: "Mortal Kombat",
  Tekken: "Tekken",
  StreetFighter: "Street Fighter",
  LordOfTheRings: "The Lord of The Rings",
} as const;

export const AI_RESPONSE_TYPES = {
  Brief: "brief",
  Expressive: "expressive",
} as const;

export const AI_RESPONSE_LANGUAGES = [
  "English (UK)",
  "English (US)",
  "Spanish",
  "French",
  "German",
  "Italian",
  "Dutch",
  "Portuguese",
  "Russian",
  "Japanese",
  "Korean",
  "Chinese Simplified",
  "Chinese Traditional",
] as const;

export const SPEECH_PROVIDER = {
  ElevenLabs: "elevenlabs",
} as const;

export const MOBILE_CHAT_HEADER_HEIGHT = 80;

export const DESKTOP_CHAT_HEADER_HEIGHT = 72;

export const MOBILE_CHAT_MSG_BOX = 62;

export const DESKTOP_CHAT_MSG_BOX = 78;

export const MOBILE_NAV_HEIGHT = 60;

export const DESKTOP_NAV_HEIGHT = 70;

export const DESKTOP_PADDING = 20;

export const SIDE_CHAT_OFFSET = 400;

export const RESPONSE_DONE_MSG = "[DONE]";

export const CODE_REPLACEMENT_SENTENCES = [
  "Refer to the code snippet I wrote in my response.",
  "Please check the code snippet included in my previous response.",
  "Take a look at the code sample I provided in my response.",
  "Check out the code I included in my response.",
  "Please see the code snippet I provided in my written reply.",
  "You can find the code snippet in my written response.",
];

export const RESPONSE_ERROR_MESSAGES = {
  NotEnoughTokens: "Oops! You don't have enough Engagement Points!",
  Unauthenticated: "Oops! You're not authenticated!",
};

export const commandName = (command: ChatCommandType) => {
  switch (command) {
    case CHAT_COMMANDS.Summarize: {
      return "Summarize Content";
    }

    case CHAT_COMMANDS.GameMode: {
      return "Game Mode";
    }

    case CHAT_COMMANDS.TutorMode: {
      return "Tutor Mode";
    }

    case CHAT_COMMANDS.ClearChat: {
      return "Clear Chat";
    }

    case CHAT_COMMANDS.Creativity: {
      return "Set Mentor Creativity";
    }

    case CHAT_COMMANDS.BriefResponse: {
      return "Set Mentor Response Mode";
    }

    case CHAT_COMMANDS.LongResponse: {
      return "Set Mentor Response Mode";
    }

    case CHAT_COMMANDS.Language: {
      return "Set Mentor Response Language";
    }
  }
};

export const isChatCommand = (text: ChatCommandType | string) => {
  return Object.values(CHAT_COMMANDS).includes(text as ChatCommandType);
};

export const formatChatCommand = (command: ChatCommandType, text?: string) => {
  return `<div class="cursor-pointer lg:tooltip tooltip-top bg-gray-900/10 px-2 py-1 rounded-md" data-tip="${commandName(command)}">${command}</div>${text ? ` ${text}` : ""}`;
};

export const formatInputFromCommand = (data: { command: string; text: string }) => {
  switch (data.command) {
    case CHAT_COMMANDS.Summarize: {
      return data.command;
    }

    case CHAT_COMMANDS.GameMode: {
      return "Let's play a game";
    }

    case CHAT_COMMANDS.TutorMode: {
      return "Let's get back to learning";
    }

    default: {
      return `${data.command} ${data.text}`;
    }
  }
};

export const commandActions = (data: { command: string; text: string }): ChatCommandAction => {
  switch (data.command) {
    case CHAT_COMMANDS.Summarize: {
      return {
        serverRequest: true,
      };
    }

    case CHAT_COMMANDS.GameMode: {
      return {
        serverRequest: true,
        customAction: true,
      };
    }

    case CHAT_COMMANDS.TutorMode: {
      return {
        serverRequest: true,
        customAction: true,
      };
    }

    case CHAT_COMMANDS.ClearChat: {
      return {
        clearChat: true,
      };
    }

    case CHAT_COMMANDS.Creativity: {
      return {
        customAction: true,
      };
    }

    case CHAT_COMMANDS.BriefResponse: {
      return {
        customAction: true,
      };
    }

    case CHAT_COMMANDS.LongResponse: {
      return {
        customAction: true,
      };
    }

    case CHAT_COMMANDS.Language: {
      return {
        customAction: true,
      };
    }

    default: {
      return {};
    }
  }
};

export const extractCommandAndTextFromInput = (input: string) => {
  input = input.trim().replaceAll(/^\n|\n$/g, "");

  const spaceIndex = input.indexOf(" ");

  if (!input || input.at(0) !== "/") {
    return false;
  }

  const command = spaceIndex === -1 ? input : input.slice(0, spaceIndex);

  if (!isChatCommand(command)) {
    return false;
  }

  return {
    command,
    text: spaceIndex === -1 ? "" : input.slice(spaceIndex + 1),
  };
};

export const chatErrorMessage = (status: ResponseStatus) => {
  switch (status) {
    case RESPONSE_STATUS.Unauthenticated: {
      return "It seems you're not authenticated.";
    }

    case RESPONSE_STATUS.Forbidden: {
      return "I'm sorry, but you can't do that.";
    }

    case RESPONSE_STATUS.NotFound: {
      return "I can't seem to find the resource you seek.";
    }

    default: {
      return "Oops! Something went wrong. Please try again later. Or check your points balance perhaps.";
    }
  }
};
